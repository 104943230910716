
import React, { useRef, useState, useEffect } from "react";
import closeIcon from '../src/assets/close-icon.png';
import playIcon from '../src/assets/play-icon.png';
import loadergif from '../src/assets/loader.gif';

var closed = false
const Sprinzo = () => {
    const [displayBG, setdisplayBG] = useState("none");
    const [display, setdisplay] = useState("block");
    const [position, setposition] = useState("fixed");
    const [height, setheight] = useState("set");
    const [maxwidth, setmaxwidth] = useState("100%");
    const [maxheight, setmaxheight] = useState("160px");
    const [top, settop] = useState(undefined);
    const [left, setleft] = useState(undefined);
    const [right, setright] = useState('0px');
    const [bottom, setbottom] = useState('0px');
    const [bigger, setbigger] = useState(false);

    const [playButOpacity, setplayButOpacity] = useState(0);
    const [speakerButOpacity, setspeakerButOpacity] = useState(0);
    const [CTAButOpacity, setCTAButOpacity] = useState(0);
    const [speakermute, setspeakermute] = useState(true);
    const [sowHosted, setsowHosted] = useState(0);
    const [pauseVideo, setpauseVideo] = useState(false);

     const vid = useRef()   
    function bgclick(event) {
        event.stopPropagation();
        if (closed) {
            return
        }
        if (!bigger) {
            setposition((!bigger) ? 'absolute' : 'fixed')
            setheight((!bigger) ? 'unset' : 'set')
            setmaxwidth((!bigger) ? '90%' : '100%')
            setmaxheight((!bigger) ? '80vh' : '160px')
            settop((!bigger) ? '50%' : undefined)
            setleft((!bigger) ? '50%' : undefined)
            setright((!bigger) ? undefined : '0px')
            setbottom((!bigger) ? undefined : '0px')
            setspeakerButOpacity(1)
            setspeakermute(false)
            setsowHosted(1)
            setbigger(true)
            setdisplayBG('block')
            setCTAButOpacity((!bigger) ? 1 : 0)
        }
        else {
            if (playButOpacity == 0) {
                vid.current.pause(); 
                setplayButOpacity(1)
            }
            else {
                vid.current.play(); 
                setplayButOpacity(0)
            }
        }
    }


    return (
        <>
            <div 
            onClick={bgclick}
             style={{ zIndex: 900, aspectRatio: '0.5625 / 1', height: height, position: position, top: top, left: left, right: right, bottom: bottom, maxWidth: maxwidth, maxHeight: maxheight, textAlign: 'center', transform: 'translate(-50%, -50%)', borderRadius: '12px', border: 'none', filter: 'drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px)', pointerEvents: 'all', cursor: 'default', backgroundColor: 'unset', transition: 'max-height 600ms ease-in-out 0s', display: display }}>
                <div onClick={() => {
                    closed = true
                    setdisplay('none')
                    setdisplayBG('none')
                    vid.current.pause()
                }} style={{ width: '15px', height: '15px', borderRadius: '100%', padding: '3px', backgroundColor: 'rgba(0, 0, 0, 0.6)', top: '-5px', left: '-5px', zIndex: 3, position: 'absolute', cursor: 'pointer', transition: 'all 600ms ease-in-out 0s' }}>
                    <img src={closeIcon} style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ position: 'absolute', top: '50%', left: '50%', width: '80px', transform: 'translate(-50%, -50%)', transition: 'opacity 300ms ease-in-out 0s', zIndex: 999, opacity: playButOpacity, display: 'block', cursor: 'pointer', pointerEvents: 'none' }}>
                    <img src={playIcon} style={{ width: '100%', filter: 'drop-shadow(0px 0px 3px #A855F7)' }} />
                </div>
                {/* <div 
                onClick={() => {
                    if (speakermute) {
                        setspeakermute(false)
                    }
                    else {
                        setspeakermute(true)
                    }

                 }} 
                style={{ width: '25px', height: '25px', borderRadius: '100%', padding: '3px', backgroundColor: 'rgba(0, 0, 0, 0.6)', top: '10px', right: '10px', position: 'absolute', cursor: 'pointer', zIndex: 1, opacity: speakerButOpacity, pointerEvents: 'none', transition: 'all 600ms ease-in-out 0s' }}>
                    {!speakermute ? <img id="speaker-image" src="https://app.storiway.com/Assets/Images/speaker.png" style={{ position: 'absolute', inset: '8px', width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', pointerEvents: 'none', opacity: 1 }} />
                        : <img src="https://app.storiway.com/Assets/Images/speaker-mute.png" id="speaker-mute-image" style={{ position: 'absolute', inset: '8px', width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', pointerEvents: 'all', opacity: 1 }} />}
                </div> */}
                <div style={{ position: 'absolute', top: '0px', marginTop: '0px', width: '30%', marginLeft: '35%', cursor: 'pointer', zIndex: 99, transition: 'all 600ms ease-in-out 0s', opacity: sowHosted, pointerEvents: 'none' }}>
                    <h4 style={{ width: '100%' }} > Hosted by Frift</h4>
                </div>
                <div style={{ position: 'absolute', width: '100%', padding: '20px 0px', bottom: '0px', textAlign: 'center', transition: 'opacity 200ms ease-in-out 0s', borderRadius: '0px 0px 12px 12px', opacity: CTAButOpacity, pointerEvents: 'none', zIndex: 9999 }}>
                    <button onClick={()=>{

                    }} style={{ display: 'block', width: '90%', margin: '10px auto', padding: '20px 30px', cursor: 'pointer', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '16px', border: 'none', fontSize: '14px', fontWeight: 600, overflow: 'hidden', whiteSpace: 'nowrap' }}>Book
                        et ophold</button>
                    <button onClick={()=>{
                        
                    }} style={{ display: 'block', width: '90%', margin: '10px auto', padding: '20px 30px', cursor: 'pointer', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '16px', border: 'none', fontSize: '14px', fontWeight: 600, overflow: 'hidden', whiteSpace: 'nowrap' }}>Gå
                        tilbage</button>
                </div>
                {/* <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', transition: 'width 600ms ease 200ms, height 600ms ease 200ms', zIndex: 999, display: 'block', opacity: 1, pointerEvents: 'none', width: '40px', height: '40px' }}>
                    <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100">
                        <circle pathLength={100} strokeDasharray={100} strokeDashoffset={60} stroke="#A855F7" strokeWidth={7} strokeLinecap="round" fill="transparent" cx={50} cy={50} r={43} />
                        <circle pathLength={100} strokeDasharray={100} strokeDashoffset={60} stroke="#A855F7" strokeWidth={7} strokeLinecap="round" fill="transparent" cx={50} cy={50} r={43} style={{ transform: 'rotateZ(180deg)', transformOrigin: 'center' }} />
                    </svg>
                </div> */}
                <video preload="auto" poster={loadergif} ref= {vid} style={{ display: 'block', width: '100%', height: '100%', borderRadius: '12px', objectFit: 'cover', cursor: 'pointer', opacity: 1, transition: 'opacity 300ms ease 0s' }} autoPlay muted={speakermute} loop>
                    <source src="https://cdn.storiway.com/clgaq2vvr000j2o0rpdd49oba/VIDEOS/4-Step---VÃ¦relser.mp4" type="video/mp4" />
                </video>
            </div>
            <div onClick={() => {
                if (bigger) {
                    setposition((!bigger) ? 'absolute' : 'fixed')
                    setheight((!bigger) ? 'unset' : 'set')
                    setmaxwidth((!bigger) ? '90%' : '100%')
                    setmaxheight((!bigger) ? '80vh' : '160px')
                    settop((!bigger) ? '50%' : undefined)
                    setleft((!bigger) ? '50%' : undefined)
                    setright((!bigger) ? undefined : '0px')
                    setbottom((!bigger) ? undefined : '0px')
                    setpauseVideo(false)
                    setplayButOpacity(0)
                    setspeakermute(true)
                    setspeakerButOpacity(0)
                    setsowHosted(0)
                    setbigger(false)
                    setdisplayBG('none')
                    setCTAButOpacity(0)
                }
            }} style={{ display: displayBG, flex: 1, width: '100%', height: '100%', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: -1 }} ></div>

        </>);
}

export default Sprinzo;
//firebase deploy --only hosting:widget