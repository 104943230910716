import logo from './logo.svg';
import './App.css';
import Sprinzo from "./Sprinzo";



function App() {
  return (
    <div className="App">
      <Sprinzo/>
    </div>
  );
}



export default App;
